import { CardContent, Link, Typography } from '@mui/material';
import type { RestoreInstanceVolumeInput } from '@repo/api-gw-sdk';
import { useState } from 'react';

import {
  EncryptionComponent,
  getEncryptionKey,
} from '@/components/encryption/encryptionComponent';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import { EonTagRemark, SnapshotToolbar } from './shared';
import { SingleVolumeConfig } from './singleVolumeConfig';

import { StepContainer } from '../../wizard/StepContainer';
import { type RestoreEc2InstanceState } from '../restoreEc2InstanceWizard';

export const VolumeConfiguration = (
  props: StepProps<RestoreEc2InstanceState>
) => {
  const dal = useDAL();
  const [errorCode, setErrorCode] = useState<number | undefined>(undefined);

  const startRestore = () => {
    setErrorCode(undefined);
    if (
      !props.wizardState.crossStepsState.instanceType ||
      !props.wizardState.crossStepsState.AWSSubnetId ||
      !props.wizardState.crossStepsState.regionName ||
      !props.wizardState.crossStepsState.tags ||
      !props.wizardState.restoreAccount
    ) {
      console.error('Missing required fields to start restore');
      return;
    }

    const volumeSettings: RestoreInstanceVolumeInput[] =
      props.wizardState.volumes.map((vol) => {
        const volConfigState =
          props.wizardState.crossStepsState.volumesConfigState?.find(
            (volumeSetting) => volumeSetting.volumeId === vol.volumeId
          );

        let volTags: RestoreInstanceVolumeInput['tags'] = {};
        if (volConfigState?.keepOriginalTags) {
          const volInitialConfigState =
            props.wizardState.initialState.volumesConfigState?.find(
              (volumeSetting) => volumeSetting.volumeId === vol.volumeId
            );
          volTags = volInitialConfigState?.tags || {};
        } else {
          volTags = volConfigState?.tags || {};
        }
        volTags['eon:restore'] = 'true';

        const currEncryption = props.wizardState.sharedEncryption?.isEncrypted
          ? props.wizardState.sharedEncryption
          : volConfigState?.encryption;
        const encryptionKey = getEncryptionKey(currEncryption);

        return {
          volumeId: vol.volumeId,
          tags: volTags,
          encryptionKeyId: encryptionKey,
          volumeEncryptionKeyId: encryptionKey,
          volumeSettings: (volConfigState || vol).volumeSettings,
        };
      });

    const tags =
      (props.wizardState.crossStepsState.keepOriginalTags
        ? props.wizardState.initialState.tags
        : props.wizardState.crossStepsState.tags) || {};
    tags['eon:restore'] = 'true';

    void dal.restore.ec2
      .restore(props.wizardState.resourceId, props.wizardState.snapshot.id, {
        restoreAccountId: props.wizardState.restoreAccount.id,
        region: props.wizardState.crossStepsState.regionName,
        instanceType: props.wizardState.crossStepsState.instanceType,
        subnetId: props.wizardState.crossStepsState.AWSSubnetId,
        securityGroupIds: props.wizardState.crossStepsState.securityGroupIds,
        instanceProfileName: props.wizardState.crossStepsState.instanceProfile,
        tags,
        volumeSettings,
      })
      .then(() => {
        props.onSuccess('Restore job has started!');
        props.abort();
      })
      .catch((error) => {
        setErrorCode(error.code);
      });
  };

  const canGoNext = () => {
    let hasEncryptionKeyId = false;

    if (props.wizardState.sharedEncryption?.isEncrypted) {
      hasEncryptionKeyId = !!getEncryptionKey(
        props.wizardState.sharedEncryption
      );
    } else {
      hasEncryptionKeyId = props.wizardState.volumes.every((vol) => {
        const currentVolEncryption =
          props.wizardState.crossStepsState.volumesConfigState?.find(
            (volConf) => volConf.volumeId === vol.volumeId
          )?.encryption;
        return !!getEncryptionKey(currentVolEncryption);
      });
    }

    if (!hasEncryptionKeyId) {
      return false;
    }

    return true;
  };

  const getNavigationComponent = () => {
    if (errorCode) {
      return (
        <Typography color='error'>
          An error prevented the restore. Please{' '}
          <Link color='error' href='mailto:support@eon.io' underline='always'>
            email Eon support{' '}
          </Link>
          for help. Error code: {errorCode}
        </Typography>
      );
    }

    return <EonTagRemark />;
  };

  return (
    <StepContainer
      sx={{ padding: '0' }}
      navigationComponent={getNavigationComponent()}
      stepperLabels={props.stepperLabels}
      stepperIndex={2}
      nextButtonText='Restore'
      onBackClick={props.back}
      canGoNext={canGoNext}
      onNextClick={startRestore}
      toolbarComponent={
        <SnapshotToolbar
          pointInTime={props.wizardState.snapshot.pointInTime}
          vaultId={props.wizardState.snapshot.vaultId}
          accessDeniedItemsCount={
            props.wizardState.snapshot.accessDeniedItemsCount
          }
        />
      }
    >
      <CardContent className='p-0'>
        {props.wizardState.crossStepsState.regionName &&
          props.wizardState.restoreAccount && (
            <EncryptionComponent
              title='Encrypt all volumes with the same key'
              canBeTurnedOff={true}
              encryption={props.wizardState.sharedEncryption}
              accountId={props.wizardState.restoreAccount.id}
              regionName={props.wizardState.crossStepsState.regionName}
              onChange={(encryption) =>
                props.setWizardState((state) => ({
                  ...state,
                  sharedEncryption: encryption,
                }))
              }
            />
          )}
      </CardContent>
      <CardContent className='p-0'>
        {props.wizardState.volumes.map((vol) => {
          const volumeSettings =
            props.wizardState.crossStepsState.volumesConfigState?.find(
              (v) => v.volumeId === vol.volumeId
            );
          const initialVolumeSettings =
            props.wizardState.initialState.volumesConfigState?.find(
              (v) => v.volumeId === vol.volumeId
            );
          if (
            !initialVolumeSettings ||
            !volumeSettings ||
            !props.wizardState.crossStepsState.regionName ||
            !props.wizardState.restoreAccount
          ) {
            return null;
          }
          return (
            <SingleVolumeConfig
              key={vol.volumeId}
              vol={vol}
              initialSettings={initialVolumeSettings}
              volumeSettings={volumeSettings}
              setWizardState={props.setWizardState}
              restoreAccountId={props.wizardState.restoreAccount.id}
              regionName={props.wizardState.crossStepsState.regionName}
              showEncryption={!props.wizardState.sharedEncryption?.isEncrypted}
            />
          );
        })}
      </CardContent>
    </StepContainer>
  );
};
