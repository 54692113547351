import ConfirmationDialog from '@/components/dialogs/confirmationDialog';

export function DeleteVpcConfigDialog(props: {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}) {
  const { isOpen, onCancel, onConfirm } = props;

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title='Delete Vpc Configuration'
      description={`Are you sure you want to delete this VPC configuration?`}
      onCancel={onCancel}
      onConfirm={onConfirm}
      buttonIconClass='material-symbols-delete-outline'
      buttonTitle='Delete'
    />
  );
}
