import { Divider, Radio, Stack, Tooltip, Typography } from '@mui/material';
import { SnapshotStatus, type Snapshot } from '@repo/api-gw-sdk';
import classnames from 'classnames';

import type { Evidence } from '@/components/security/securityScanCard';
import {
  getEvidenceTypeLabel,
  isHaveSecurityIssue,
} from '@/components/security/securityUtils';
import { SkullIcon } from '@/components/security/skullIcon';
import { Icon } from '@/components/shared/icon';
import { SnapshotIndicator } from '@/components/snapshots/SnapshotIndicator';
import VaultTag from '@/components/vaults/vaultTag';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import type { BackupVault } from '@/data/vaults/backupVault';
import { isDateValid } from '@/utils/dateTime';
import { dayjs, TIME_FORMAT } from '@/utils/dayjs';

import { PendingCircle } from './pendingCircle';

interface SnapshotDetailsHeaderProps {
  snap: Snapshot;
  vault: BackupVault | undefined;
  isSelected: boolean;
  onSelect: () => void;
  snapshotSelection: boolean;
}

export const SnapshotDetailsHeader = ({
  snap,
  isSelected,
  onSelect,
  vault,
  snapshotSelection,
}: SnapshotDetailsHeaderProps) => {
  const { showPendingSnapshots, partialSnapshotsIndication, securityScan } =
    useFeatureFlags();
  const date = isDateValid(snap.pointInTime)
    ? snap.pointInTime
    : snap.createTime;
  const showPartial =
    partialSnapshotsIndication &&
    snap.status !== SnapshotStatus.Pending &&
    snap.accessDeniedItemsCount;

  return (
    <Stack
      className={classnames('flex-1', { 'cursor-pointer': snapshotSelection })}
      direction='row'
      gap='40px'
      alignItems='center'
      onClick={() => onSelect()}
      sx={{
        '& .info-icon': {
          visibility: 'hidden',
        },
        '&:hover': {
          '& .info-icon': {
            visibility: showPartial ? 'visible' : 'hidden',
          },
        },
      }}
    >
      <Stack direction='row' gap='12px' alignItems='center'>
        {snapshotSelection && <Radio size='small' checked={isSelected} />}
        {vault &&
          showPendingSnapshots &&
          snap.status === SnapshotStatus.Pending && (
            <PendingCircle backgroundColor={vault.backgroundColor} />
          )}
        {vault && snap.status !== SnapshotStatus.Pending && (
          <SnapshotIndicator
            backgroundColor={vault.backgroundColor || null}
            isPartial={!!snap.accessDeniedItemsCount}
          />
        )}
        {snap.status !== SnapshotStatus.Pending && (
          <Typography width='100px' variant='body1'>
            {`${dayjs.utc(date).format(TIME_FORMAT)} UTC`}
          </Typography>
        )}
        <Stack width='102px' direction={'row'} gap='24px' alignItems='center'>
          <Tooltip
            title={`This is a partial snapshot. ${
              snap.accessDeniedItemsCount
            } files weren’t backed up.`}
            slotProps={{
              tooltip: {
                sx: {
                  padding: '16px',
                },
              },
            }}
          >
            <Icon
              iconClass='material-symbols-info-outline'
              className='info-icon'
            />
          </Tooltip>
          {securityScan && isHaveSecurityIssue(snap) && (
            <>
              {showPartial ? (
                <Divider orientation='vertical' flexItem />
              ) : (
                // having a div, to keep the margin achieve by the stack gap
                <div />
              )}
              <RansomwareIndication snap={snap} />
            </>
          )}
        </Stack>
        {showPendingSnapshots && snap.status === SnapshotStatus.Pending && (
          <Typography variant='body1'>
            {`Started at ${dayjs.utc(date).format(TIME_FORMAT)} UTC`}
          </Typography>
        )}
      </Stack>
      {vault && (
        <Stack direction='row' gap='12px' alignItems='center'>
          <Typography variant='body1'>Stored in</Typography>
          <VaultTag vault={vault} />
        </Stack>
      )}
    </Stack>
  );
};

const RansomwareIndication = ({ snap }: { snap: Snapshot }) => {
  const evidences: Evidence[] = JSON.parse(
    snap.securityScan?.summaryJson || '[]'
  );

  return (
    <Tooltip
      PopperProps={{ style: { width: '240px' } }}
      title={
        <Stack padding='4px' gap='12px'>
          <Typography
            className='font-semibold'
            color='var(--mui-palette-customColors-tooltipText)'
          >
            Ransomware indications
          </Typography>
          <Stack gap='4px'>
            {evidences.map((evidence) => (
              <Stack
                key={evidence.evidencetype}
                direction='row'
                justifyContent='space-between'
              >
                <Typography color='var(--mui-palette-customColors-tooltipText)'>
                  {getEvidenceTypeLabel(evidence.evidencetype)?.countLabel}
                </Typography>
                <Typography color='var(--mui-palette-customColors-tooltipText)'>
                  {evidence.Count}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      }
    >
      <span>
        <SkullIcon />
      </span>
    </Tooltip>
  );
};
