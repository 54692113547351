import { Divider, Stack, Typography, Tooltip } from '@mui/material';

import { Icon } from '@/components/shared/icon';
import { SnapshotIndicator } from '@/components/snapshots/SnapshotIndicator';
import { Tag } from '@/components/tag';
import VaultTag from '@/components/vaults/vaultTag';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import useBackupVaults from '@/data/vaults/useBackupVaults';
import { DATE_FORMAT, dayjs, TIME_FORMAT } from '@/utils/dayjs';

export const EonTagRemark = () => (
  <Stack alignItems='center' direction='row'>
    <Typography className='mr-[12px]'>{`Eon's restoration tag will be added`}</Typography>
    <Tag variant='outlined' content='eon:restore=true' />
    <Tooltip
      slotProps={{
        tooltip: {
          sx: {
            padding: '16px',
          },
        },
      }}
      title={`By using the tag, you can identify the resources you've restored.`}
    >
      <Icon iconClass='material-symbols-help-outline-rounded' />
    </Tooltip>
  </Stack>
);

export const SnapshotToolbar = ({
  pointInTime,
  vaultId,
  accessDeniedItemsCount,
}: {
  pointInTime?: Date;
  vaultId: string;
  accessDeniedItemsCount?: number;
}) => {
  const { data: vaults } = useBackupVaults();
  const { partialSnapshotsIndication } = useFeatureFlags();

  const vault = vaults.find((v) => v.id === vaultId);
  if (!vault) {
    return null;
  }

  return (
    <Stack
      sx={{
        background: 'var(--mui-palette-background-default)',
        margin: '0px 40px',
        padding: '16px 24px',
        borderRadius: '4px',
      }}
      divider={<Divider className='my-[12px]' />}
    >
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography>Snapshot time</Typography>
        <Stack direction='row' alignItems='center' gap='12px'>
          <SnapshotIndicator
            backgroundColor={vault.backgroundColor || null}
            isPartial={!!accessDeniedItemsCount}
          />
          <Typography>
            {`${dayjs.utc(pointInTime).format(DATE_FORMAT)} at ${dayjs.utc(pointInTime).format(TIME_FORMAT)} UTC`}
          </Typography>
          <Divider orientation='vertical' flexItem className='mx-[12px]' />
          <Typography>Stored in</Typography>
          <VaultTag vault={vault} />
        </Stack>
      </Stack>
      {partialSnapshotsIndication && !!accessDeniedItemsCount && (
        <Typography>
          {`This is a partial snapshot. ${accessDeniedItemsCount} files were not backed up.`}
        </Typography>
      )}
    </Stack>
  );
};
