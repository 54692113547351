import { styled, keyframes } from '@mui/material';

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

export const PITCalendarWrapper = styled('div')(function ({ theme }) {
  const isDarkMode = theme.palette.mode === 'dark';

  return {
    padding: '24px 40px',
    maxWidth: '100%',
    overflow: 'auto',
    '& button.fc-button-primary.fc-next-button, & button.fc-button-primary.fc-prev-button':
      {
        borderRadius: '50% !important',
        width: '40px',
      },
    '& button.fc-button-primary.fc-next-button:focus, & button.fc-button-primary.fc-prev-button:focus':
      {
        boxShadow: 'none',
      },
    '& button.fc-button-primary.fc-today-button': {
      marginRight: '8px',
    },
    '& button.fc-button-primary:hover': {
      backgroundColor:
        'rgba(var(--mui-palette-action-activeChannel) / var(--mui-palette-action-hoverOpacity))',
      color: 'var(--mui-palette-primary-main)',
    },
    '& button.fc-button-primary': {
      backgroundColor: 'transparent',
      color: 'var(--mui-palette-primary-main)',
      border: 'none',
      height: '40px',
    },
    '& button.fc-button-primary:disabled': {
      backgroundColor: 'transparent',
      color: 'var(--mui-palette-primary-main)',
      opacity: 0.5,
    },
    '& .fc-header-toolbar': {
      '&.fc-toolbar': {
        margin: '0 3px 8px',
      },
    },

    '& .fc-theme-standard td, & .fc-theme-standard th': {
      border: 'none',
    },

    '& .fc .fc-toolbar-title': {
      fontSize: '16px',
    },

    '& .fc .fc-scrollgrid': {
      border: 'none',
    },

    '& .fc .fc-daygrid-day-bottom': {
      display: 'none',
    },

    '& div.fc-daygrid-day-frame': {
      margin: '4px',
      border: '1px solid var(--mui-palette-divider)',
      borderRadius: '8px',
      height: '88px',
      width: 'calc(100% - 8px)',
      minHeight: 'unset',
      cursor: 'pointer',
      padding: '2px',
    },

    '& div.fc-daygrid-day-frame:hover': {
      border: `1px solid ${isDarkMode ? '#ffdada' : '#272742'}`,
    },

    '& .fc .fc-daygrid-day.fc-day-today, & .fc .fc-day-disabled': {
      backgroundColor: 'unset',
    },

    '& .fc .fc-daygrid-day.cell-selected div.fc-daygrid-day-frame': {
      border: `3px solid ${isDarkMode ? '#ffdada' : '#272742'}`,
      padding: 0,
    },

    '& .fc .fc-daygrid-day.cell-loading div.fc-daygrid-day-frame': {
      animation: `${pulse} 4s infinite`,
    },

    '& .fc-daygrid-day.fc-day-today div.fc-daygrid-day-frame': {
      border: `3px solid var(--mui-palette-divider)`,
      padding: 0,
    },

    '& .fc-daygrid-day.fc-day-today.fc-day-disabled div.fc-daygrid-day-frame, .fc-daygrid-day.fc-day-today.fc-day-disabled div.fc-daygrid-day-frame:hover':
      {
        border: `3px solid var(--mui-palette-divider)`,
        padding: 0,
      },

    '& .fc-daygrid-day.fc-day-today div.fc-daygrid-day-frame:hover': {
      border: `3px solid ${isDarkMode ? '#ffdada' : '#272742'}`,
      padding: 0,
    },

    '& .fc-daygrid-day.fc-day-disabled div.fc-daygrid-day-frame, .fc-daygrid-day.fc-day-disabled div.fc-daygrid-day-frame:hover':
      {
        cursor: 'default',
        backgroundColor: isDarkMode ? '#f5f5f514' : '#F5F5F5',
        border: 'none',
      },

    '& .fc .fc-highlight': {
      background: 'transparent',
    },

    '& .fc .fc-daygrid-day-top': {
      justifyContent: 'center',
      marginTop: '22px',
    },
    '& .fc .fc-daygrid-day-number': {
      fontSize: '13px',
    },

    '& .fc .fc-daygrid-day-events': {
      marginTop: '-4px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& .fc .fc-event:hover, & .fc .fc-event:focus, & .fc .fc-event:focus::after, .fc .fc-h-event':
      {
        backgroundColor: 'unset',
        boxShadow: 'none',
        border: 'none,',
      },
  };
});
