import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';

import { useDAL } from '@/data/dal';

import { EmptySelect } from './emptySelect';

import SelectPlaceholder from '../selectPlaceholder';

const title = 'VPC*';

export const VpcSelect = ({
  accountId,
  regionName,
  vpc,
  onChange,
  disabledItems,
}: {
  accountId?: string;
  regionName?: string;
  vpc?: string;
  onChange: (vpc: string) => void;
  disabledItems: string[];
}) => {
  if (!accountId || !regionName) {
    return <EmptySelect title={title} />;
  }

  return (
    <InnerVpcSelect
      accountId={accountId}
      regionName={regionName}
      vpc={vpc}
      onChange={onChange}
      disabledItems={disabledItems}
    />
  );
};

const InnerVpcSelect = ({
  accountId,
  regionName,
  vpc,
  onChange,
  disabledItems,
}: {
  accountId: string;
  regionName: string;
  vpc?: string;
  onChange: (vpc: string) => void;
  disabledItems: string[];
}) => {
  const dal = useDAL();
  const { body } = dal.restore.awsSubnets.list(accountId, regionName);

  if (!body?.subnets.length) {
    return <EmptySelect title={title} />;
  }

  const options = Array.from(
    new Set(body.subnets.map((value) => value.vpc))
  ).sort();

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>{title}</FormLabel>
      <Select
        data-testid='vpsSelect'
        size='small'
        value={vpc || ''}
        onChange={(event) => onChange(event.target.value)}
        displayEmpty
        renderValue={(value: string) => {
          if (!value) {
            return <SelectPlaceholder />;
          }
          return value;
        }}
      >
        {options.map((value, idx) => (
          <MenuItem
            data-testid={`vpc-${idx}`}
            key={value}
            value={value}
            disabled={disabledItems.includes(value)}
          >
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
