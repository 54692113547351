import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { BackupStatus } from '@repo/api-gw-sdk';

import {
  dbEngine,
  resourceGroupName,
  resourceId,
  resourceType,
  sourceRegion,
  tags,
} from '@/components/queryBuilder/properties';
import { PropertyGroups } from '@/components/queryBuilder/propertyGroups';
import { SecurityScanCard } from '@/components/security/securityScanCard';
import { SecurityScanMutedBar } from '@/components/security/securityScanMutedBar';
import {
  isHaveSecurityIssue,
  isSecurityAlertsMuted,
} from '@/components/security/securityUtils';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import { groupActionsByLevel } from '@/data/inventory/actions';

import BackupStatusComponent from './backupStatus';
import { Classifications } from './classifications';
import { DatabaseSection } from './databaseSection';
import InstanceActionsBar from './instanceActionsBar';
import { JobsSection } from './jobsSection';
import { SidePanelPropsTable } from './shared';
import { VolumesSection } from './volumesSection';

import type { InstancePanelProps } from '../../instancePanel';

export const OverviewTab = (props: InstancePanelProps) => {
  const { entity } = props;
  const { securityScan: securityScanEnabled } = useFeatureFlags();

  const fields = props.fields
    .map((x) => ({
      id: x.id,
      title: x.title(entity),
      group: x.group,
      value: x.value(entity),
    }))
    .filter((x) => !!x.value);

  const actions = groupActionsByLevel(entity.backupStatus, props.actions);

  return (
    <Box className='pb-[40px]' overflow='auto'>
      <Box className='px-[40px] pt-[40px]'>
        <Typography fontSize={'20px'} data-testid='side-panel-resource-name'>
          {entity.resourceName}
        </Typography>
        <SidePanelPropsTable className='mt-[24px] mb-[24px]'>
          <tbody>
            {[
              fields.find((x) => x.id === resourceType.name)!,
              fields.find((x) => x.id === resourceId.name)!,
              fields.find((x) => x.id === resourceGroupName.name)!,
              fields.find((x) => x.id === sourceRegion.name)!,
              fields.find((x) => x.id === dbEngine.name)!,
              fields.find((x) => x.id === tags.name)!,
            ]
              .filter(Boolean)
              .map((x) => (
                <tr key={x.id}>
                  <td className='props-col'>{x.title}</td>
                  <td>{x.value}</td>
                </tr>
              ))}
          </tbody>
        </SidePanelPropsTable>
      </Box>
      <InstanceActionsBar entity={entity} actions={actions} />
      <Stack gap='24px' paddingX='40px' margin={'40px 0 24px'}>
        {securityScanEnabled &&
          isHaveSecurityIssue(entity) &&
          !isSecurityAlertsMuted(entity) && (
            <SecurityScanCard
              resource={entity}
              onEntityChange={props.onEntityChange}
            />
          )}
        <BackupStatusComponent
          entity={entity}
          backupStatus={entity.backupStatus}
          onEntityChange={props.onEntityChange}
          resourceId={props.entity.id}
          actions={actions}
        />
        {securityScanEnabled && isSecurityAlertsMuted(entity) && (
          <SecurityScanMutedBar
            resourceId={props.entity.id}
            onEntityChange={props.onEntityChange}
          />
        )}
      </Stack>

      {entity.backupStatus !== BackupStatus.InitialClassification && (
        <Accordion disableGutters>
          <AccordionSummary data-testid='classifications'>
            {PropertyGroups.classifications.title}
          </AccordionSummary>
          <AccordionDetails>
            <Classifications entity={entity} />
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion disableGutters>
        <AccordionSummary>
          {PropertyGroups['backup-details'].title}
        </AccordionSummary>
        <AccordionDetails className='px-0 py-[4px]'>
          <SidePanelPropsTable>
            <TableBody>
              {fields
                .filter((x) => x.group === 'backup-details')
                .map((x, index, items) => (
                  <TableRow key={x.id}>
                    <TableCell
                      className='py-[20px] pl-[40px]'
                      sx={{
                        width: '210px',
                        verticalAlign: 'top',
                        borderBottomWidth:
                          index === items.length - 1 ? '0' : '1px',
                      }}
                    >
                      {x.title}
                    </TableCell>
                    <TableCell
                      className='py-[20px]'
                      sx={{
                        borderBottomWidth:
                          index === items.length - 1 ? '0' : '1px',
                      }}
                    >
                      {x.value}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </SidePanelPropsTable>
        </AccordionDetails>
      </Accordion>
      <Accordion disableGutters>
        <AccordionSummary>
          {PropertyGroups['source-location'].title}
        </AccordionSummary>
        <AccordionDetails className='px-0 py-[4px]'>
          <SidePanelPropsTable>
            <TableBody>
              {fields
                .filter((x) => x.group === 'source-location')
                .map((x, index, items) => (
                  <TableRow key={x.id}>
                    <TableCell
                      className='py-[20px] pl-[40px]'
                      sx={{
                        width: '210px',
                        verticalAlign: 'top',
                        borderBottomWidth:
                          index === items.length - 1 ? '0' : '1px',
                      }}
                    >
                      {x.title}
                    </TableCell>
                    <TableCell
                      className='py-[20px]'
                      sx={{
                        borderBottomWidth:
                          index === items.length - 1 ? '0' : '1px',
                      }}
                    >
                      {x.value}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </SidePanelPropsTable>
        </AccordionDetails>
      </Accordion>
      {entity.volumes?.length && (
        <Accordion disableGutters defaultExpanded={false}>
          <AccordionSummary>{'Volumes'}</AccordionSummary>
          <AccordionDetails className='px-0'>
            <VolumesSection resource={entity} />
          </AccordionDetails>
        </Accordion>
      )}
      {entity.databaseProperties && (
        <Accordion disableGutters defaultExpanded={false}>
          <AccordionSummary>{'Database properties'}</AccordionSummary>
          <AccordionDetails className='px-0'>
            <DatabaseSection resource={entity} />
          </AccordionDetails>
        </Accordion>
      )}
      <Accordion disableGutters defaultExpanded={false}>
        <AccordionSummary>{'Jobs'}</AccordionSummary>
        <AccordionDetails className='px-0'>
          <JobsSection resource={entity} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
