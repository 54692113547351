import type {
  Account,
  InventoryResource,
  Snapshot,
  SnapshotPropertiesAzurePropertiesDiskPropertiesInner,
} from '@repo/api-gw-sdk';
import { Provider } from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import { AzureDiskConfiguration } from './steps/azureDiskConfiguration';
import { SelectTargetAccountStep } from './steps/selectTargetAccount';

import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface RestoreAzureDiskState {
  resourceId: string;
  snapshot: Snapshot;
  supportedCloudProviders: Provider[];
  restoreAccount?: Account;
  resourceGroup?: string;
  location?: string;

  tags?: Record<string, string>;
  initialTags?: Record<string, string>;
  keepOriginalTags?: boolean;
  initialDiskSettings: SnapshotPropertiesAzurePropertiesDiskPropertiesInner;
  diskSettings?: SnapshotPropertiesAzurePropertiesDiskPropertiesInner;
  keepOriginalSettings?: boolean;
}

const restoreAzureDiskFlow: Step<RestoreAzureDiskState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccountStep,
  next: [
    {
      name: 'AzureDiskConfiguration',
      Component: AzureDiskConfiguration,
    },
  ],
};

export const RestoreAzureDiskWizard = ({
  resource,
  snapshot,
  disk,
}: {
  resource: InventoryResource;
  snapshot: Snapshot;
  disk: SnapshotPropertiesAzurePropertiesDiskPropertiesInner;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: {
        id: resource.id,
        onClose: () => setIsOpen(false),
        initialTab: 'Eon snapshots',
      },
    });

    return true;
  };

  return (
    <Wizard<RestoreAzureDiskState>
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      onFinish={close}
      flow={[restoreAzureDiskFlow]}
      initialState={{
        snapshot,
        resourceId: resource.id,
        supportedCloudProviders: [Provider.Azure],
        initialDiskSettings: disk,
        initialTags: { ...disk.tags },
        keepOriginalTags: true,
        keepOriginalSettings: true,
      }}
      title={`Restore Disk`}
      stepperLabels={['Restore account', 'Disk configuration']}
    />
  );
};
