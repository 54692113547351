import { Divider, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { ResourceType, type InventoryResource } from '@repo/api-gw-sdk';
import React from 'react';

import { OtherSnapshotsMultipleDots } from '@/components/snapshots/otherSnapshotsMultipleDots';
import { CloudProviders } from '@/data/inventory/data';

interface OtherSnapshotsToggleProps {
  resource: InventoryResource;
  showOtherSnapshots: boolean;
  setShowOtherSnapshots: (show: boolean) => void;
}

export default function OtherSnapshotsToggle(props: OtherSnapshotsToggleProps) {
  const { resource, showOtherSnapshots, setShowOtherSnapshots } = props;
  const tooltipTitle = `This resource doesn’t have any ${CloudProviders[resource.cloudProvider]?.displayName} snapshots in the source account.`;
  const disabled = !resource.snapshotStorage.otherSnapshotCount;

  return (
    !!resource.snapshotStorage.otherSnapshotCount &&
    resource.resourceType === ResourceType.AwsEc2 && (
      <>
        <Stack
          padding='16px 40px'
          direction='row'
          gap='12px'
          alignItems='center'
          justifyContent='space-between'
        >
          <Stack direction='row' gap='12px' alignItems='center'>
            <OtherSnapshotsMultipleDots />
            <Typography>{`Show ${CloudProviders[resource.cloudProvider]?.displayName} snapshots`}</Typography>
          </Stack>
          <Tooltip title={tooltipTitle} disableHoverListener={!disabled}>
            <span>
              <Switch
                disabled={disabled}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#F1A81B',
                  },
                }}
                checked={showOtherSnapshots}
                onChange={(e) => setShowOtherSnapshots(e.target.checked)}
                size='small'
              />
            </span>
          </Tooltip>
        </Stack>
        <Divider />
      </>
    )
  );
}
