import {
  Autocomplete,
  FormControl,
  FormLabel,
  TextField,
  type AutocompleteRenderInputParams,
} from '@mui/material';
import { useEffect } from 'react';

import { useDAL } from '@/data/dal';

import { EmptySelect } from './emptySelect';

const title = 'Subnet';

export const AWSSubnetSelect = ({
  accountId,
  regionName,
  subnetId,
  onChange,
  hideLabel,
  availabilityZoneScope,
  vpcScope,
}: {
  accountId?: string;
  regionName?: string;
  subnetId?: string;
  availabilityZoneScope?: string;
  vpcScope?: string;
  onChange: (subnetId?: string, isUserChange?: boolean) => void;
  hideLabel?: boolean;
}) => {
  if (!accountId || !regionName) {
    return <EmptySelect title={hideLabel ? '' : title} />;
  }

  return (
    <InnerAWSSubnetSelect
      accountId={accountId}
      regionName={regionName}
      subnetId={subnetId}
      onChange={onChange}
      hideLabel={hideLabel}
      availabilityZoneScope={availabilityZoneScope}
      vpcScope={vpcScope}
    />
  );
};

const InnerAWSSubnetSelect = ({
  accountId,
  regionName,
  subnetId,
  onChange,
  hideLabel,
  availabilityZoneScope,
  vpcScope,
}: {
  accountId: string;
  regionName: string;
  subnetId?: string;
  availabilityZoneScope?: string;
  vpcScope?: string;
  onChange: (subnetId?: string, event?: boolean) => void;
  hideLabel?: boolean;
}) => {
  const dal = useDAL();
  const { body, isLoading } = dal.restore.awsSubnets.list(
    accountId,
    regionName
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (subnetId && !body?.subnets?.some((x) => x.id === subnetId)) {
      onChange(undefined);
    }
  }, [body?.subnets, isLoading, onChange, subnetId]);

  if (!body?.subnets?.length) {
    return <EmptySelect title={hideLabel ? '' : title} />;
  }

  const availableSubnets = body.subnets
    .filter(
      (x) =>
        !availabilityZoneScope || x.availabilityZone === availabilityZoneScope
    )
    .filter((x) => !vpcScope || x.vpc === vpcScope);

  return (
    <FormControl size='small' className='flex-1'>
      {!hideLabel && <FormLabel>{title}</FormLabel>}
      <Autocomplete
        clearOnBlur
        data-testid='awsSubnetSelect'
        size='small'
        value={subnetId || ''}
        onChange={(event, newValue) => onChange(newValue || undefined, !!event)}
        renderInput={(params: AutocompleteRenderInputParams) => {
          return (
            <TextField
              {...params}
              sx={{
                '& input::placeholder': {
                  color: 'var(--mui-palette-text-primary)',
                  opacity: 1,
                },
              }}
              placeholder='Select'
              label=''
              InputLabelProps={{ shrink: true }}
            />
          );
        }}
        options={availableSubnets.map((subnet) => subnet.id)}
      ></Autocomplete>
    </FormControl>
  );
};
