import { Divider, Stack, Typography } from '@mui/material';
import type {
  CloudSnapshotsPerVolume,
  InventoryResource,
  SnapshotLocation,
} from '@repo/api-gw-sdk';
import classnames from 'classnames';
import { useEffect, useState } from 'react';

import { MultiAccordionsItem } from '@/components/accordion/multiAccordionsItem';
import ButtonWithIcon from '@/components/buttons/buttonWithIcon';
import { Loader } from '@/components/layout/loading';
import { OtherSnapshotDot } from '@/components/snapshots/otherSnapshotDot';
import SnapshotSelectionEmptyState from '@/components/snapshots/snapshotSelection/snapshotSelectionEmptyState';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';
import dayjs, { TIME_FORMAT } from '@/utils/dayjs';

import { Panels } from '../../panels';

interface OtherSnapshotsTabProps {
  resource: InventoryResource;
  selectedDate: Date;
}

function groupSnapshotsByStartedAt(snapshots: CloudSnapshotsPerVolume[]) {
  const grouped: Record<
    string,
    { volumeId: string; snapshotLocation: SnapshotLocation }[]
  > = {};

  snapshots
    .filter(
      (snap) =>
        !!snap.volumeId &&
        !!snap.snapshotsData &&
        !!snap.snapshotsData.every((s) => !!s.snapshotLocation)
    )
    .forEach(({ volumeId, snapshotsData }) => {
      snapshotsData!.forEach((snapshot) => {
        if (!snapshot.startedAt) return;

        const normalizedStartedAt = dayjs(snapshot.startedAt)
          .utc()
          .startOf('minute')
          .toISOString();

        if (!grouped[normalizedStartedAt]) {
          grouped[normalizedStartedAt] = [];
        }

        grouped[normalizedStartedAt].push({
          volumeId: volumeId!,
          snapshotLocation: snapshot.snapshotLocation!,
        });
      });
    });

  return grouped;
}

export const OtherSnapshotsTab = (props: OtherSnapshotsTabProps) => {
  const { selectedDate, resource } = props;
  const { convertToEonSnapshot: convertToEonSnapshotEnabled } =
    useFeatureFlags();
  const dal = useDAL();
  const { body: providerSnapshots, isLoading } = convertToEonSnapshotEnabled
    ? dal.convert.listCloudSnapshots(resource.id, selectedDate)
    : { body: { snapshotsPerVolume: [] }, isLoading: false };
  const snapshots = providerSnapshots?.snapshotsPerVolume || [];
  const volumes = snapshots.map((snap) => snap.volumeId);
  const numOfVols = new Set(volumes).size;
  const groupedByTime = groupSnapshotsByStartedAt(snapshots);
  const filteredTimes = Object.keys(groupedByTime).filter(
    (time) => groupedByTime[time].length === numOfVols
  );
  const [selectedTime, setSelectedTime] = useState<string>(filteredTimes[0]);
  const { rightPanel } = useWorkspace();
  const closeRightPanel = () => {
    rightPanel.setIsOpen(false);
  };

  useEffect(() => {
    setSelectedTime(filteredTimes[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapshots]);

  if (isLoading) {
    return (
      <Stack marginY='40px'>
        <Loader />
      </Stack>
    );
  }

  if (!filteredTimes.length) {
    return (
      <Stack marginY='40px'>
        <SnapshotSelectionEmptyState
          description={`Couldn't find related snapshots for volumes`}
        />
      </Stack>
    );
  }

  return (
    <Stack width='100%'>
      {filteredTimes.map((time) => {
        const timeSnapshots = groupedByTime[time];
        return (
          <>
            <MultiAccordionsItem
              key={time}
              isSelected={selectedTime === time}
              onSelect={() => setSelectedTime(time)}
              isMulti={!!filteredTimes.length}
              Summary={
                <Stack
                  gap='80px'
                  direction='row'
                  alignItems='center'
                  className={classnames('flex-1', {
                    'cursor-pointer': filteredTimes.length,
                  })}
                >
                  <Stack
                    width='132px'
                    direction='row'
                    gap='12px'
                    alignItems='center'
                  >
                    <OtherSnapshotDot />
                    <Typography variant='body1'>
                      {`${dayjs.utc(time).format(TIME_FORMAT)} UTC`}
                    </Typography>
                  </Stack>
                  <Typography variant='body1'>
                    {timeSnapshots.length}{' '}
                    {timeSnapshots.length > 1 ? 'volumes' : 'volume'}
                  </Typography>
                </Stack>
              }
              Details={
                <Stack marginLeft={'212px'} gap='24px'>
                  <Stack direction='column' gap='16px'>
                    {timeSnapshots.map((timeSnap) => {
                      const vol = timeSnap.volumeId;
                      const snapId = timeSnap.snapshotLocation.snapshotId;
                      return (
                        <Stack key={vol} direction='row' gap='14px'>
                          <Typography width='170px'>{vol}</Typography>
                          <Divider orientation='vertical' flexItem />
                          <Typography>{snapId}</Typography>
                        </Stack>
                      );
                    })}
                  </Stack>
                  <Stack alignItems='end'>
                    <ButtonWithIcon
                      variant='outlined'
                      icon='material-symbols-join-right-rounded'
                      text={'Convert to Eon'}
                      onClick={() => {
                        rightPanel.setComponent({
                          panel: Panels.ConvertToSingleEonSnapshot,
                          props: {
                            onClose: closeRightPanel,
                            back: () =>
                              rightPanel.setComponent({
                                panel: Panels.InventoryInstance,
                                props: {
                                  onClose: closeRightPanel,
                                  id: resource.id,
                                  initialTab: 'Eon snapshots',
                                },
                              }),
                            resourceId: resource.id,
                            snapshotDetails: timeSnapshots,
                            snapshotDate: time,
                          },
                        });
                        rightPanel.setIsOpen(true);
                      }}
                    />
                  </Stack>
                </Stack>
              }
            />
            <Divider />
          </>
        );
      })}
    </Stack>
  );
};
