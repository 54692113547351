import {
  Button,
  Dialog,
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import type { CloudAccountConfigurationAwsConfigInner } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import { Transition } from '@/components/animations/transitions';
import { AWSSubnetSelect } from '@/components/regions/awsSubnetSelect';
import { SecurityGroupSelect } from '@/components/regions/securityGroupSelect';
import { Icon } from '@/components/shared/icon';

import { cloudAccountConfigurationAwsSecurityGroupsKeys } from './types';

export const CloudAccountConfigurationAwsConfigDialog = ({
  accountId,
  config: initialConfig,
  availabilityZones,
  onSave,
  onCancel,
}: {
  accountId: string;
  config: CloudAccountConfigurationAwsConfigInner | undefined;
  availabilityZones: string[];
  onCancel: () => void;
  onSave: (config: CloudAccountConfigurationAwsConfigInner) => void;
}) => {
  const [config, setConfig] = useState<
    CloudAccountConfigurationAwsConfigInner | undefined
  >(initialConfig ? { ...initialConfig } : undefined);

  useEffect(() => {
    setConfig(initialConfig ? { ...initialConfig } : undefined);
  }, [initialConfig]);

  return (
    <Dialog
      TransitionComponent={Transition}
      open={!!config}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: '100%',
          height: '85%',
          margin: 0,
          alignSelf: 'end',
          marginLeft: '60px',
        },
      }}
    >
      <Stack className='h-full'>
        <Stack
          gap='12px'
          className='py-[16px] px-[24px] overflow-y-auto'
          flexGrow={1}
        >
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography className='font-semibold'>
              Custom VPC settings
            </Typography>
            <Icon
              onClick={onCancel}
              iconClass='material-symbols-close-rounded'
            />
          </Stack>
          <Stack className='mx-[60px] mt-[24px]' gap='24px' flexGrow={1}>
            <Stack direction='row' gap='4px'>
              <Typography
                component='span'
                fontWeight={300}
              >{`Customizing settings for`}</Typography>
              <Typography component='span' className='font-semibold'>
                {`${config?.vpc}.`}
              </Typography>
            </Stack>
            <Typography
              className='w-[700px]'
              fontWeight={300}
              lineHeight={'20px'}
            >{`Define the subnets and security groups the restore server can use in the VPC. Make sure to configure the subnets and security groups so they can be used by Eon.`}</Typography>
            <Divider className='mx-[-150px]' />
            <Stack direction='row' gap='100px'>
              <Stack gap='36px' className='w-[240px] pt-[8px]'>
                <Typography className='font-semibold'>
                  Subnets per AZ
                </Typography>
                <Typography lineHeight={'20px'} fontWeight={300}>
                  {`The restore server will use the specified subnet in the
                    availability zone it’s restoring to.`}
                </Typography>
              </Stack>
              <Stack
                flexGrow={1}
                gap='24px'
                divider={<Divider sx={{ borderStyle: 'dashed' }} />}
              >
                {availabilityZones.sort().map((az) => {
                  const subnetId =
                    config?.cloudAccountConfigurationAwsSubnets.find(
                      (y) => y.availabilityZone === az
                    )?.subnetId;

                  return (
                    <Stack
                      key={az}
                      direction='row'
                      alignItems='center'
                      gap='40px'
                      className='h-[33px]'
                    >
                      <Typography className='w-[150px]'>{az}</Typography>
                      <FormControlLabel
                        labelPlacement='start'
                        control={<Switch className='ml-[12px]' />}
                        label='Customize'
                        checked={subnetId !== undefined}
                        onChange={(event, checked) => {
                          setConfig((prev) => {
                            if (!prev) {
                              return prev;
                            }

                            const newState = {
                              ...prev,
                              cloudAccountConfigurationAwsSubnets:
                                prev.cloudAccountConfigurationAwsSubnets.filter(
                                  (y) => y.availabilityZone !== az
                                ),
                            };

                            if (checked) {
                              newState.cloudAccountConfigurationAwsSubnets.push(
                                {
                                  availabilityZone: az,
                                  subnetId: '',
                                }
                              );
                            }

                            return newState;
                          });
                        }}
                      />
                      <Typography
                        color={
                          subnetId === undefined
                            ? 'var(--mui-palette-text-disabled)'
                            : undefined
                        }
                        width={'180px'}
                        textAlign={'right'}
                      >
                        {subnetId === undefined
                          ? 'Use default subnet'
                          : 'Use subnet:'}
                      </Typography>
                      {subnetId !== undefined && (
                        <Stack className='w-[250px]'>
                          <AWSSubnetSelect
                            hideLabel
                            accountId={accountId}
                            regionName={config?.region}
                            subnetId={subnetId}
                            availabilityZoneScope={az}
                            vpcScope={config?.vpc}
                            onChange={(subnet) => {
                              setConfig((prev) => {
                                if (!prev || !subnet) {
                                  return prev;
                                }

                                const newState = {
                                  ...prev,
                                  cloudAccountConfigurationAwsSubnets:
                                    prev.cloudAccountConfigurationAwsSubnets.map(
                                      (y) => {
                                        if (y.availabilityZone === az) {
                                          return {
                                            ...y,
                                            subnetId: subnet,
                                          };
                                        }

                                        return y;
                                      }
                                    ),
                                };

                                return newState;
                              });
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
            <Divider className='my-[20px]' />
            <Stack direction='row' gap='100px'>
              <Stack gap='36px' className='w-[240px] pt-[8px]'>
                <Typography className='font-semibold'>
                  Security groups per resource type
                </Typography>
                <Typography lineHeight={'20px'} fontWeight={300}>
                  {`The restore server will use the specified security group for the type of resource being restored.`}
                </Typography>
              </Stack>
              <Stack
                flexGrow={1}
                gap='24px'
                divider={<Divider sx={{ borderStyle: 'dashed' }} />}
              >
                {Object.entries(
                  cloudAccountConfigurationAwsSecurityGroupsKeys
                ).map(([key, title]) => {
                  const securityGroupId =
                    config?.cloudAccountConfigurationAwsSecurityGroups.find(
                      (y) => y.key === key
                    )?.securityGroupId;

                  return (
                    <Stack
                      key={key}
                      direction='row'
                      alignItems='center'
                      gap='40px'
                      className='h-[33px]'
                    >
                      <Typography className='w-[150px]'>{title}</Typography>
                      <FormControlLabel
                        labelPlacement='start'
                        control={<Switch className='ml-[12px]' />}
                        label='Customize'
                        checked={securityGroupId !== undefined}
                        onChange={(event, checked) => {
                          setConfig((prev) => {
                            if (!prev) {
                              return prev;
                            }

                            const newState = {
                              ...prev,
                              cloudAccountConfigurationAwsSecurityGroups:
                                prev.cloudAccountConfigurationAwsSecurityGroups.filter(
                                  (y) => y.key !== key
                                ),
                            };

                            if (checked) {
                              newState.cloudAccountConfigurationAwsSecurityGroups.push(
                                {
                                  key,
                                  securityGroupId: '',
                                }
                              );
                            }

                            return newState;
                          });
                        }}
                      />
                      <Typography
                        color={
                          securityGroupId === undefined
                            ? 'var(--mui-palette-text-disabled)'
                            : undefined
                        }
                        width={'180px'}
                        textAlign={'right'}
                      >
                        {securityGroupId === undefined
                          ? 'Use default security group'
                          : 'Use security group:'}
                      </Typography>
                      {securityGroupId !== undefined && (
                        <Stack className='w-[250px]'>
                          <SecurityGroupSelect
                            isOptionalField={true}
                            hideLabel
                            accountId={accountId}
                            regionName={config?.region}
                            vpc={config?.vpc}
                            securityGroupIds={[securityGroupId]}
                            onChange={(securityGroupIds) => {
                              setConfig((prev) => {
                                if (!prev || !securityGroupIds?.[0]) {
                                  return prev;
                                }

                                const newState = {
                                  ...prev,
                                  cloudAccountConfigurationAwsSecurityGroups:
                                    prev.cloudAccountConfigurationAwsSecurityGroups.map(
                                      (y) => {
                                        if (y.key === key) {
                                          return {
                                            ...y,
                                            securityGroupId:
                                              securityGroupIds[0],
                                          };
                                        }

                                        return y;
                                      }
                                    ),
                                };

                                return newState;
                              });
                            }}
                          />
                        </Stack>
                      )}
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction='row'
          className='justify-end items-center px-[40px] py-[16px]'
          sx={{ boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.20)' }}
        >
          <Button className='mr-[12px]' onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant='contained'
            disabled={
              !config ||
              (!config.cloudAccountConfigurationAwsSubnets.length &&
                !config.cloudAccountConfigurationAwsSecurityGroups.length) ||
              config.cloudAccountConfigurationAwsSubnets.some(
                (x) => !x.availabilityZone || !x.subnetId
              ) ||
              config.cloudAccountConfigurationAwsSecurityGroups.some(
                (x) => !x.key || !x.securityGroupId
              )
            }
            onClick={() => {
              if (config) {
                config.cloudAccountConfigurationAwsSubnets =
                  config.cloudAccountConfigurationAwsSubnets.filter(
                    (x) => x.availabilityZone && x.subnetId
                  );

                config.cloudAccountConfigurationAwsSecurityGroups =
                  config.cloudAccountConfigurationAwsSecurityGroups.filter(
                    (x) => x.key && x.securityGroupId
                  );

                onSave(config);
              }
            }}
          >
            Save Settings
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
