import type {
  InventoryResource,
  Account,
  FileSearchRecord,
  FileSnapshot,
} from '@repo/api-gw-sdk';
import { Provider } from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import type { RestoreGenericStorageProps } from './bucketSelection';
import { FileConfiguration } from './steps/fileConfiguration';
import { SelectTargetAccountStep } from './steps/selectTargetAccount';

import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface RestoreFileState extends RestoreGenericStorageProps {
  actionType: string;
  resourceIdForSnapshots?: string;
  fileSnapshot: FileSnapshot;
  vaultId: string;
  supportedCloudProviders: Provider[];
  restoreAccount?: Account;
  files: { path: string; isDirectory: boolean }[];
}

const restoreFileFlow: Step<RestoreFileState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccountStep,
  next: [
    {
      name: 'FileConfiguration',
      Component: FileConfiguration,
    },
  ],
};

interface Props {
  entity?: FileSearchRecord;
  path: string;
  fileSnapshot: FileSnapshot;
  vaultId: string;
  resource: InventoryResource;
}

export const RestoreFileWizard = (props: Props) => {
  const { rightPanel } = useWorkspace();
  const { setIsOpen } = rightPanel;

  const close = () => {
    setIsOpen(false);

    return true;
  };

  const files = [{ path: props.path, isDirectory: false }];

  return (
    <Wizard<RestoreFileState>
      onFinish={close}
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      flow={[restoreFileFlow]}
      initialState={{
        files,
        actionType: 'file',
        fileSnapshot: props.fileSnapshot,
        vaultId: props.vaultId,
        resourceIdForSnapshots: props.resource.id,
        restoreMode: 'select',
        S3: {},
        StorageAccount: {},
        supportedCloudProviders: [Provider.Aws, Provider.Azure],
      }}
      title={'Restore File'}
      stepperLabels={['Restore Account', 'Configuration']}
    />
  );
};
