import { Box, Stack, styled, Tooltip, Typography } from '@mui/material';
import { type InventoryResource } from '@repo/api-gw-sdk';
import { useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionDivider,
  AccordionSummary,
} from '@/components/accordion';
import { CircleImage } from '@/components/shared/circleImage';
import { Icon } from '@/components/shared/icon';
import { Tag } from '@/components/tag';
import {
  ApplicationsLogosWithAliases,
  Environments,
  DataClasses,
} from '@/data/inventory/data';

import { AppsEditor } from './classificationPanels/appsEditor';
import { AppsViewer } from './classificationPanels/appsViewer';
import { DataClassEditor } from './classificationPanels/dataClassEditor';
import { DataClassViewer } from './classificationPanels/dataClassViewer';
import { EnvironmentEditor } from './classificationPanels/environmentEditor';
import { EnvironmentViewer } from './classificationPanels/environmentViewer';
import { RestartApplicationDetection } from './classificationPanels/restartApplicationDetection';
import { RestartAutomaticDataClassClassification } from './classificationPanels/restartAutomaticDataClassification';
import { RestartAutomaticEnvironmentDetection } from './classificationPanels/restartAutomaticEnvironmentDetection';

const LeftPaddedAccordionDetails = styled(AccordionDetails)(() => ({
  paddingRight: 0,
}));

export function Classifications({
  entity,
}: {
  entity: InventoryResource | undefined;
}) {
  const [dataClassEditorOpen, setDataClassEditorOpen] = useState(false);
  const [restartDataClassEditorOpen, setRestartDataClassEditorOpen] =
    useState(false);
  const [environmentEditorOpen, setEnvironmentEditorOpen] = useState(false);
  const [restartEnvironmentEditorOpen, setRestartEnvironmentEditorOpen] =
    useState(false);
  const [applicationsEditorOpen, setApplicationsEditorOpen] = useState(false);
  const [restartApplicationsEditorOpen, setRestartApplicationsEditorOpen] =
    useState(false);

  const handleDataClassEditorOpen = () => {
    setDataClassEditorOpen(true);
  };
  const handleDataClassEditorClose = () => {
    setDataClassEditorOpen(false);
  };
  const handleRestartClassificationModalOpen = () => {
    setRestartDataClassEditorOpen(true);
  };
  const handleRestartClassificationModalClose = () => {
    setRestartDataClassEditorOpen(false);
  };
  const handleEnvironmentEditorOpen = () => {
    setEnvironmentEditorOpen(true);
  };
  const handleEnvironmentEditorClose = () => {
    setEnvironmentEditorOpen(false);
  };
  const handleRestartEnvironmentEditorOpen = () => {
    setRestartEnvironmentEditorOpen(true);
  };
  const handleRestartEnvironmentEditorClose = () => {
    setRestartEnvironmentEditorOpen(false);
  };
  const handleApplicationsEditorOpen = () => {
    setApplicationsEditorOpen(true);
  };
  const handleApplicationsEditorClose = () => {
    setApplicationsEditorOpen(false);
  };
  const onResumeApplicationClassification = () => {
    setRestartApplicationsEditorOpen(true);
  };
  const handleRestartApplicationsEditorClose = () => {
    setRestartApplicationsEditorOpen(false);
  };

  if (!entity) {
    return null;
  }

  const apps = (entity.classifications?.appsDetails?.apps ?? [])
    .slice(0, 7)
    .map((x) => x.name);
  if (
    entity.classifications?.appsDetails?.apps?.length &&
    entity.classifications.appsDetails.apps.length > 7
  ) {
    apps.push(`${entity.classifications.appsDetails.apps.length - 7} more...`);
    window.reportError(
      new RangeError(
        `The user has installed many applications on the resource ${
          entity.providerResourceId
        }:${entity.accountId}`
      )
    );
  }

  return (
    <>
      <Accordion>
        <AccordionSummary
          data-testid='side-panel-classifications-data-classes'
          sx={{
            '.Mui-expanded': {
              '.data-classes': {
                opacity: '0',
              },
            },
          }}
        >
          <Stack
            className='w-full'
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography className='font-semibold'>Data classes</Typography>
            <Stack
              gap='8px'
              direction='row'
              className='data-classes'
              sx={{
                transition: 'linear 0.1s',
              }}
            >
              {entity?.classifications?.dataClassesDetails?.dataClasses?.map(
                (x) => (
                  <Tooltip key={x} title={DataClasses[x]?.title}>
                    <Tag variant='filled' content={x} />
                  </Tooltip>
                )
              )}
            </Stack>
          </Stack>
        </AccordionSummary>
        <LeftPaddedAccordionDetails>
          <DataClassViewer
            entity={entity}
            setDataClassOpen={handleDataClassEditorOpen}
            setRestartDetectionModalOpen={handleRestartClassificationModalOpen}
          />
        </LeftPaddedAccordionDetails>
      </Accordion>
      <AccordionDivider />
      <Accordion>
        <AccordionSummary
          data-testid='side-panel-classifications-environment'
          sx={{
            '.Mui-expanded': {
              '.environment': {
                opacity: '0',
              },
            },
          }}
        >
          <Stack
            className='w-full'
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography className='font-semibold'>Environment</Typography>
            {!!entity.classifications?.environmentDetails?.environment &&
              Environments[
                entity.classifications.environmentDetails.environment
              ] && (
                <Box
                  className='flex environment'
                  sx={{
                    transition: 'linear 0.1s',
                  }}
                >
                  <Typography className='font-light'>
                    {
                      Environments[
                        entity.classifications.environmentDetails.environment
                      ].title
                    }
                  </Typography>
                </Box>
              )}
          </Stack>
        </AccordionSummary>
        <LeftPaddedAccordionDetails>
          <EnvironmentViewer
            entity={entity}
            setEnvironmentDetectionOff={handleEnvironmentEditorOpen}
            restartEnvironmentDetection={handleRestartEnvironmentEditorOpen}
          />
        </LeftPaddedAccordionDetails>
      </Accordion>
      <AccordionDivider />
      <Accordion>
        <AccordionSummary
          data-testid='side-panel-classifications-applications'
          sx={{
            '.Mui-expanded': {
              '.apps': {
                opacity: '0',
              },
            },
          }}
        >
          <Stack
            className='w-full'
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography className='font-semibold'>Apps</Typography>
            <Box
              className='flex apps'
              sx={{
                transition: 'linear 0.1s',
              }}
            >
              {apps?.map((v, i) =>
                i === 7 ? ( // 3 is the index of the 'more' tag
                  <Tooltip title={v} key={i}>
                    <Icon
                      iconClass='material-symbols-more-horiz'
                      sx={{ width: '30px', height: '30px', marginLeft: '4px' }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={v} key={i}>
                    <CircleImage
                      alt={v}
                      src={ApplicationsLogosWithAliases[v]}
                    />
                  </Tooltip>
                )
              )}
            </Box>
          </Stack>
        </AccordionSummary>
        <LeftPaddedAccordionDetails>
          <AppsViewer
            entity={entity}
            onEditApplications={handleApplicationsEditorOpen}
            onResumeApplicationClassification={
              onResumeApplicationClassification
            }
          />
        </LeftPaddedAccordionDetails>
      </Accordion>
      <DataClassEditor
        open={dataClassEditorOpen}
        onClose={handleDataClassEditorClose}
        entities={[entity]}
      />
      <RestartAutomaticDataClassClassification
        open={restartDataClassEditorOpen}
        onClose={handleRestartClassificationModalClose}
        entities={[entity]}
      />
      <EnvironmentEditor
        open={environmentEditorOpen}
        onClose={handleEnvironmentEditorClose}
        entities={[entity]}
      />
      <RestartAutomaticEnvironmentDetection
        open={restartEnvironmentEditorOpen}
        onClose={handleRestartEnvironmentEditorClose}
        entities={[entity]}
      />
      <AppsEditor
        open={applicationsEditorOpen}
        onClose={handleApplicationsEditorClose}
        entities={[entity]}
      />
      <RestartApplicationDetection
        open={restartApplicationsEditorOpen}
        onClose={handleRestartApplicationsEditorClose}
        entities={[entity]}
      />
    </>
  );
}
