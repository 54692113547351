import {
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';
import React, { useEffect, useState } from 'react';

import { useEnvironment } from '@/contexts/useEnvironment';
import { useRoles } from '@/contexts/useRoles';
import { useUser } from '@/contexts/useUser';
import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';
import type { ResourceAction } from '@/data/inventory/actions';

import { BackupPoliciesTab } from './instance/tabs/backupPoliciesTab';
import { EonSnapshotsTab } from './instance/tabs/eonSnapshotsTab';
import { OverviewTab } from './instance/tabs/overviewTab';
import { PanelWrapper } from './panelWrapper';

import { Loader } from '../layout/loading';

export interface InstancePanelProps {
  entity: InventoryResource;
  initialTab?: ResourcePanelTabs;
  onEntityChange?: () => Promise<void>;
  fields: {
    id: string;
    title: (entity: InventoryResource) => string;
    group: string | undefined;
    value: (entity: InventoryResource) => React.ReactNode;
  }[];
  actions: ResourceAction[];
}

export type ResourcePanelTabs =
  | 'Overview'
  | 'Backup policies'
  | 'Eon snapshots';

const tabs = [
  {
    name: 'Overview',
    Component: (props: InstancePanelProps) => <OverviewTab {...props} />,
    predicate: () => true,
  },
  {
    name: 'Backup policies',
    Component: (props: InstancePanelProps) => (
      <BackupPoliciesTab activePolicies={props.entity.backupPolicies} />
    ),
    predicate: (entity: InventoryResource) => !!entity.backupPolicies?.length,
  },
  {
    name: 'Eon snapshots',
    dataTestId: 'eon-snapshots-tab',
    Component: (props: InstancePanelProps) => (
      <EonSnapshotsTab entity={props.entity} />
    ),
    predicate: () => true,
  },
];

const InstancePanelComponent = (props: InstancePanelProps) => {
  const { initialTab } = props;

  const shownTabs = tabs.filter((x) => x.predicate(props.entity));
  const [selectedTabName, setSelectedTabName] = useState(
    initialTab || shownTabs[0].name
  );

  useEffect(() => {
    // Sometimes switching across resources we get to a resource without the selected tab in that case we go to the first tab
    const navigateToNonExistingTab = !shownTabs.some(
      (x) => x.name === selectedTabName
    );
    if (navigateToNonExistingTab) {
      setSelectedTabName(shownTabs[0].name);
    }
  }, [selectedTabName, shownTabs]);

  const selectedTab = tabs.find((x) => x.name === selectedTabName)!;

  return (
    <Stack direction='column' overflow='hidden' flexGrow='1'>
      {shownTabs.length > 1 && (
        <Tabs
          onChange={(e, i) => setSelectedTabName(shownTabs[i].name)}
          value={shownTabs.indexOf(selectedTab)}
          className='px-[40px] min-h-[50px]'
          sx={{ '.MuiTabs-scroller': { display: 'flex' } }}
        >
          {shownTabs.map((value) => (
            <Tab
              data-testid={value.dataTestId}
              key={value.name}
              label={value.name}
              className='text-[14px] font-normal'
              disableRipple
            />
          ))}
        </Tabs>
      )}
      {selectedTab?.Component && <selectedTab.Component {...props} />}
    </Stack>
  );
};

export const InstancePanel = ({
  isLoading,
  onClose,
  entity,
  onEntityChange,
  fields,
  actions,
  initialTab,
}: {
  initialTab?: ResourcePanelTabs;
  isLoading: boolean;
  onClose: () => void;
  entity: InventoryResource | undefined;
  onEntityChange?: () => Promise<void>;
  fields: InstancePanelProps['fields'];
  actions: InstancePanelProps['actions'];
}) => {
  const dal = useDAL();
  const { isAuthorizedResource } = useRoles();
  const { user } = useUser();
  const { isDev } = useEnvironment();
  const { snackbar } = useWorkspace();

  const [disabled, setDisabled] = useState(false);
  if (isLoading) {
    return <Loader />;
  }

  if (!entity) {
    onClose();
    return null;
  }

  const Title = (
    <Stack direction='row' alignItems='center'>
      <Typography className='font-semibold'>Resource Details</Typography>

      {(isDev || user?.email?.endsWith('@eon.io')) && (
        <>
          <Tooltip title='Scan Now'>
            <IconButton
              className='ml-auto mr-20px'
              size='small'
              disabled={
                !isAuthorizedResource('create:jobs', entity) || disabled
              }
              onClick={() => {
                snackbar.showMessage('Scan Initiated');
                setDisabled(true);
                void dal.inventory.jobs
                  .createScanJob(entity.id)
                  .catch((e) => {
                    snackbar.showMessage('Scan Failed');
                    throw e;
                  })
                  .finally(() => {
                    setDisabled(false);
                  });
              }}
            >
              <i className='material-symbols-avg-pace-rounded' />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Stack>
  );

  return (
    <PanelWrapper
      header={{
        title: Title,
        onClose,

        hideBorder: true,
      }}
      testIdPrefix='instance-panel'
    >
      <InstancePanelComponent
        entity={entity}
        onEntityChange={onEntityChange}
        actions={actions}
        fields={fields}
        initialTab={initialTab}
      />
    </PanelWrapper>
  );
};
