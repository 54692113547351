import { Box, Button, MenuItem, Popover, Typography } from '@mui/material';
import type { BackupSchedule } from '@repo/api-gw-sdk';
import { useRef, useState } from 'react';

import OptionMenu from '@core/components/option-menu';
import type {
  OptionMenuItemType,
  OptionType,
} from '@core/components/option-menu/types';

import {
  Schedule,
  ScheduleContainer,
  ScheduleMenuItem,
  ScheduleSelect,
} from '@/components/schedule/schedule';
import { BackupVaultSelectionDialog } from '@/components/vaults/backupVaultSelectionDialog';
import VaultTag from '@/components/vaults/vaultTag';
import useBackupVaults from '@/data/vaults/useBackupVaults';
import { fromDaysToBiggestAsPossible } from '@/utils/dateTime';

import { frequencyMapping } from '../controls/MinimumRetentionEditor';
import RetentionSelector from '../controls/retentionSelector';

interface BackupPolicyScheduleProps {
  schedule: BackupSchedule;
  deleteAllowed?: boolean;
  onUpdate: (schedule: BackupSchedule) => void;
  onDuplicate: (schedule: BackupSchedule) => void;
  onDelete: (schedule: BackupSchedule) => void;
}

export function BackupPolicySchedule(props: BackupPolicyScheduleProps) {
  const { data: vaults } = useBackupVaults();
  const [dialogOpen, setDialogOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const updateWindow = (windows: string) => {
    const fragment = frequencyMapping.find((x) => x.windows === windows);
    if (!fragment) {
      return;
    }

    const backupRetention = Math.max(
      props.schedule.backupRetention,
      fragment.minimumRetentionInDays
    );

    props.onUpdate({
      ...props.schedule,
      windows,
      backupRetention,
    });
  };

  const updateRetention = (retentionInDays: number) => {
    const fragment = frequencyMapping.find(
      (x) => x.windows === props.schedule.windows
    );
    if (!fragment) {
      return;
    }

    const backupRetention = Math.max(
      retentionInDays,
      fragment.minimumRetentionInDays
    );

    props.onUpdate({
      ...props.schedule,
      backupRetention,
    });
  };

  const formattedRetention = fromDaysToBiggestAsPossible(
    props.schedule.backupRetention || 1
  );

  const vault = vaults.find((v) => v.id === props.schedule.vaultId);

  return (
    <ScheduleContainer>
      <Schedule direction='row' alignItems='center' gap='8px'>
        <Typography variant='body1'>Take</Typography>
        <ScheduleSelect
          MenuProps={{
            disablePortal: true,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            sx: {
              marginTop: '-2px',
            },
          }}
          value={props.schedule.windows}
          onChange={(e) => updateWindow(e.target.value as string)}
          variant='standard'
          data-testid='create-backup-policy-frequency-select'
        >
          {frequencyMapping.map((r) => (
            <ScheduleMenuItem key={r.windows} value={r.windows}>
              <Typography variant='body1'>{r.text.toLowerCase()}</Typography>
            </ScheduleMenuItem>
          ))}
        </ScheduleSelect>
        <Typography variant='body1'>and retain for</Typography>
        <>
          <Button
            sx={{
              padding: '5px 12px',
            }}
            ref={anchorRef}
            onClick={() => setPopoverOpen(true)}
            data-testid='create-backup-policy-retention-select'
            disableFocusRipple
          >
            <Typography
              fontWeight={600}
            >{`${formattedRetention.value} ${formattedRetention.unit}`}</Typography>

            <i className='material-symbols-arrow-drop-down-rounded text-[22px] ml-[4px]' />
          </Button>
          <Popover
            disablePortal
            open={popoverOpen}
            anchorEl={anchorRef.current}
            onClose={() => setPopoverOpen(false)}
          >
            <Box className='flex flex-col p-[24px] gap-[24px] w-[300px]'>
              <Typography variant='subtitle1'>
                Choose a retention time
              </Typography>
              <RetentionSelector
                initialFragment={formattedRetention.unit}
                initialRetentionDays={props.schedule.backupRetention}
                onChange={updateRetention}
              />
            </Box>
          </Popover>
        </>
        <Typography variant='body1'>on</Typography>
        <ScheduleSelect
          sx={{
            '& .MuiSelect-select:focus': {
              backgroundColor: 'transparent',
            },
            '& .MuiTypography-root': {
              color: '#ACB4B6',
            },
          }}
          data-testid='create-backup-policy-vault-select'
          variant='standard'
          value={props.schedule.vaultId || 0}
          open={false}
          native={false}
          onClick={() => {
            setDialogOpen(true);
          }}
        >
          {(!props.schedule.vaultId || !vault) && (
            <MenuItem value={props.schedule.vaultId || 0}>
              <Typography variant='body1'>Choose vault</Typography>
            </MenuItem>
          )}

          {props.schedule.vaultId && vault && (
            <MenuItem value={props.schedule.vaultId}>
              <VaultTag
                vault={vault}
                sx={{ margin: 0, marginRight: '16px', cursor: 'pointer' }}
              />
            </MenuItem>
          )}
        </ScheduleSelect>
        <OptionMenu
          iconClassName='text-textPrimary'
          icon='material-symbols-more-horiz'
          options={[
            {
              testId: 'duplicate-backup-policy',
              text: 'Duplicate',
            },
            {
              testId: 'delete-backup-policy',
              text: 'Delete',
              menuItemProps: { disabled: !props.deleteAllowed },
            },
          ]}
          onOptionSelected={(option: OptionType): void => {
            switch ((option as OptionMenuItemType).text) {
              case 'Duplicate':
                props.onDuplicate(props.schedule);
                break;
              case 'Delete':
                props.onDelete(props.schedule);
                break;
            }
          }}
        />
      </Schedule>
      <BackupVaultSelectionDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSelect={(vault) => {
          props.onUpdate({ ...props.schedule, vaultId: vault.id });
          setDialogOpen(false);
        }}
        scheduleWindow={(
          frequencyMapping.find((f) => f.windows === props.schedule.windows)
            ?.text || ''
        ).toLowerCase()}
        selectedVaults={[props.schedule.vaultId]}
      />
    </ScheduleContainer>
  );
}
