import { Button, Paper, Popper, Stack, Typography } from '@mui/material';
import type { CloudAccountConfigurationAwsConfigInner } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import { RegionSelect } from '@/components/regions/regionSelect';
import { VpcSelect } from '@/components/regions/vpcSelect';

export const VpcSelectionDialog = ({
  accountId,
  onClose,
  buttonRef,
  config,
}: {
  accountId: string;
  onClose: (regionName: string | undefined, vpc: string | undefined) => void;
  buttonRef: React.RefObject<HTMLButtonElement>;
  config: CloudAccountConfigurationAwsConfigInner[];
}) => {
  const [regionName, setRegionName] = useState<string | undefined>('us-east-1');
  const [vpc, setVpc] = useState<string | undefined>(undefined);

  useEffect(() => {
    setVpc(undefined);
  }, [regionName]);

  return (
    <Popper
      anchorEl={buttonRef.current}
      disablePortal
      placement='bottom-start'
      open
    >
      <Paper
        className='shadow-lg'
        sx={{
          width: '308px',
          padding: '24px',
          borderRadius: '4px',
          marginTop: '-33px',
        }}
      >
        <Stack gap='24px'>
          <Typography>View VPCs for region</Typography>
          <RegionSelect
            accountId={accountId}
            regionName={regionName}
            onChange={(regionName) => setRegionName(regionName)}
          />
          <VpcSelect
            disabledItems={config
              .filter((x) => x.region === regionName)
              .map((c) => c.vpc)}
            vpc={vpc}
            regionName={regionName}
            accountId={accountId}
            onChange={setVpc}
          />
          <Stack direction='row' gap='8px' justifyContent={'flex-end'}>
            <Button onClick={() => onClose(undefined, undefined)}>
              Cancel
            </Button>
            <Button
              onClick={() => onClose(regionName, vpc)}
              variant='contained'
              disabled={!regionName || !vpc}
            >
              Edit Setting
            </Button>
          </Stack>
        </Stack>
      </Paper>
    </Popper>
  );
};
