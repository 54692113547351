import type {
  Account,
  InventoryResource,
  Snapshot,
  SnapshotPropertiesAzurePropertiesDiskPropertiesInner,
} from '@repo/api-gw-sdk';
import { Provider } from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import { AzureVmConfiguration } from './steps/azureVmConfiguration';
import { AzureVmDiskConfiguration } from './steps/azureVmDiskConfiguration';
import { SelectTargetAccountStep } from './steps/selectTargetAccount';

import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface AzureDiskConfigState {
  diskSettings: SnapshotPropertiesAzurePropertiesDiskPropertiesInner;
  keepOriginalTags: boolean;
  keepOriginalSettings: boolean;
  initialTags?: Record<string, string>;
  tags?: Record<string, string>;
}

export interface RestoreAzureVMState {
  resourceId: string;
  snapshot: Snapshot;
  supportedCloudProviders: Provider[];
  restoreAccount?: Account;
  resourceGroup?: string;
  location?: string;
  vmName?: string;
  originalVMSize?: string;
  vmSize?: string;
  networkInterface?: string;
  keepOriginalTags: boolean;
  initialTags?: Record<string, string>;
  tags?: Record<string, string>;
  disksState: AzureDiskConfigState[];
  initialDiskStates: AzureDiskConfigState[];
}

const restoreAzureVMInstanceFlow: Step<RestoreAzureVMState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccountStep,
  next: [
    {
      name: 'AzureVmConfiguration',
      Component: AzureVmConfiguration,
      next: [
        {
          name: 'DiskConfiguration',
          Component: AzureVmDiskConfiguration,
        },
      ],
    },
  ],
};

export const RestoreAzureVMWizard = ({
  resource,
  snapshot,
}: {
  resource: InventoryResource;
  snapshot: Snapshot;
  sourceRegion?: string;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: {
        id: resource.id,
        onClose: () => setIsOpen(false),
        initialTab: 'Eon snapshots',
      },
    });

    return true;
  };

  const diskStates =
    snapshot.resourceProperties?.azureProperties?.diskProperties?.map(
      (disk) => ({
        diskSettings: disk,
        diskId: disk.diskId ?? '',
        diskName: disk.diskName ?? '',
        diskType: disk.diskType ?? '',
        diskTier: disk.diskTier ?? '',
        diskSizeBytes: disk.diskSize ?? 0,
        diskHyperVGeneration: disk.diskHyperVGeneration ?? '',
        keepOriginalTags: true,
        keepOriginalSettings: true,
        initialTags: { ...disk.tags },
        tags: {},
      })
    ) ?? [];

  return (
    <Wizard<RestoreAzureVMState>
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      onFinish={close}
      flow={[restoreAzureVMInstanceFlow]}
      initialState={{
        snapshot,
        resourceId: resource.id,
        supportedCloudProviders: [Provider.Azure],
        keepOriginalTags: true,
        vmName: snapshot.resourceProperties?.azureProperties?.name ?? '',
        vmSize: snapshot.resourceProperties?.azureProperties?.vmSize,
        originalVMSize: snapshot.resourceProperties?.azureProperties?.vmSize,
        initialTags: { ...snapshot.resourceProperties?.tags },
        tags: {},
        initialDiskStates: diskStates,
        disksState: diskStates,
      }}
      title={`Restore Virtual Machine`}
      stepperLabels={[
        'Restore account',
        'Virtual Machine configuration',
        'Disk configuration',
      ]}
    />
  );
};
