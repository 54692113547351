import {
  Box,
  FormControl,
  FormLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import type { SnapshotPropertiesAzurePropertiesDiskPropertiesInner } from '@repo/api-gw-sdk';

import { fileSizeFormatter } from '@/utils/fileSizeFormatter';

import { TransactionalTextField } from '../shared/transactionalTextField';

interface Props {
  accountId: string;
  location?: string;
  initialSettings?: SnapshotPropertiesAzurePropertiesDiskPropertiesInner;
  settings?: SnapshotPropertiesAzurePropertiesDiskPropertiesInner;
  onChange: (
    settings: SnapshotPropertiesAzurePropertiesDiskPropertiesInner
  ) => void;
  keepOriginalSettings?: boolean;
  setKeepOriginalSettings: (value: boolean) => void;
}

export const DiskSettingsSection = ({
  initialSettings,
  settings: _settings,
  keepOriginalSettings,
  onChange,
  setKeepOriginalSettings,
}: Props) => {
  // const dal = useDAL();
  // const { body } = dal.restore.azureRestoreConfig.listSKUs(accountId, location ?? '','disks');

  const settings = _settings || initialSettings;
  // const uniqueNames = Array.from(
  //   new Set((body?.skus || []).map((x) => x.name))
  // ).sort();

  return (
    <Box className='mx-[40px] my-[24px]'>
      <Box className='flex justify-between items-center'>
        <Typography className='font-semibold' variant='body1'>
          Keep original settings
        </Typography>
        <Switch
          size='small'
          checked={keepOriginalSettings}
          onChange={() => {
            setKeepOriginalSettings(!keepOriginalSettings);
          }}
        />
      </Box>
      {keepOriginalSettings && (
        <Box className='mt-[10px]'>
          <Table size='small'>
            <TableBody>
              <TableRow>
                <TableCell
                  className='border-0 px-0'
                  sx={{ color: 'var(--mui-palette-text-disabled)' }}
                >
                  Name
                </TableCell>
                <TableCell
                  className='border-0 px-0'
                  sx={{ color: 'var(--mui-palette-text-disabled)' }}
                >
                  {initialSettings?.diskName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className='border-0 px-0'
                  sx={{ color: 'var(--mui-palette-text-disabled)' }}
                >
                  Type
                </TableCell>
                <TableCell
                  className='border-0 px-0'
                  sx={{ color: 'var(--mui-palette-text-disabled)' }}
                >
                  {initialSettings?.diskType}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className='border-0 px-0'
                  sx={{ color: 'var(--mui-palette-text-disabled)' }}
                >
                  Tier
                </TableCell>
                <TableCell
                  className='border-0 px-0'
                  sx={{ color: 'var(--mui-palette-text-disabled)' }}
                >
                  {initialSettings?.diskTier}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className='border-0 px-0'
                  sx={{ color: 'var(--mui-palette-text-disabled)' }}
                >
                  Size
                </TableCell>
                <TableCell
                  className='border-0 px-0'
                  sx={{ color: 'var(--mui-palette-text-disabled)' }}
                >
                  {fileSizeFormatter(initialSettings?.diskSize, 1, false)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className='border-0 px-0'
                  sx={{ color: 'var(--mui-palette-text-disabled)' }}
                >
                  Hyper VGeneration
                </TableCell>
                <TableCell
                  className='border-0 px-0'
                  sx={{ color: 'var(--mui-palette-text-disabled)' }}
                >
                  {initialSettings?.diskHyperVGeneration}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      )}
      {!keepOriginalSettings && (
        <Box className='mt-[20px] w-1/2 pr-[18px]'>
          <FormControl size='small' className='w-full mb-[24px]'>
            <FormLabel>Disk Name*</FormLabel>
            <TransactionalTextField
              initValue={settings?.diskName ?? ''}
              onChange={(diskName) => onChange({ ...settings!, diskName })}
            />
          </FormControl>
          {/* <FormControl size='small' className='w-full mb-[24px]'>
            <FormLabel>Type</FormLabel>
            <Select value={settings?.diskType || ''} onChange={() => {}}>
              {uniqueNames.map((sku) => (
                <MenuItem key={sku} value={sku}>
                  {sku}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
        </Box>
      )}
    </Box>
  );
};
