import { Stack } from '@mui/material';
import type {
  SnapshotPropertiesAzurePropertiesDiskPropertiesInner,
  SnapshotPropertiesVolumePropertiesInner,
  BackupVault,
  InventoryResource,
  Snapshot,
} from '@repo/api-gw-sdk';
import { ResourceType, Provider } from '@repo/api-gw-sdk';
import React, { useEffect, useState } from 'react';

import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import { useWorkspace } from '@/contexts/useWorkspace';
import { isDateValid } from '@/utils/dateTime';

import { OtherSnapshotsTab } from './otherSnapshotsTab';
import { SnapshotDrawerHeader } from './snapshotDrawerHeader';
import { SnapshotRow } from './snapshotRow';
import type { SnapshotsOrigin } from './snapshotsOriginTabs';
import { VMSnapshotRow, type RestoreActionType } from './vmSnapshotRow';

import { Panels } from '../../panels';
import type { PanelTypes } from '../../panelsMap';

const RestorePanels: Partial<
  Record<
    ResourceType,
    'RestoreS3Wizard' | 'RestoreRDSWizard' | 'RestoreMongoAtlasWizard'
  >
> = {
  [ResourceType.AwsS3]: Panels.RestoreS3Wizard,
  [ResourceType.AzureStorageAccount]: Panels.RestoreS3Wizard,
  [ResourceType.AwsRds]: Panels.RestoreRDSWizard,
  [ResourceType.AtlasMongodbCluster]: Panels.RestoreMongoAtlasWizard,
};

interface RestoreSnapshotBarProps {
  vaults: BackupVault[];
  eonSnapshots: Snapshot[];
  hasProviderSnapsInDate: boolean;
  selectedDate: Date;
  entity: InventoryResource;
  onClose: () => void;
}

export const RestoreSnapshotDrawer = (props: RestoreSnapshotBarProps) => {
  const { convertToEonSnapshot: convertToEonSnapshotEnabled } =
    useFeatureFlags();
  const {
    vaults,
    eonSnapshots,
    hasProviderSnapsInDate,
    selectedDate,
    entity,
    onClose,
  } = props;
  const { rightPanel } = useWorkspace();
  const { setComponent } = rightPanel;
  const [selectedOrigin, setSelectedOrigin] = useState<SnapshotsOrigin>(
    hasProviderSnapsInDate ? 'other' : 'eon'
  );
  const [selectedDateSnapshots, setSelectedDateSnapshots] = useState<
    Snapshot[]
  >([]);
  const [selectedSnapshot, setSelectedSnapshot] = React.useState<string>('');

  const restorePanel = RestorePanels[entity.resourceType];

  useEffect(() => {
    const dateSnapshots = selectedDate
      ? (eonSnapshots ?? [])
          .filter((e) => {
            const date = isDateValid(e.pointInTime)
              ? e.pointInTime
              : e.createTime;

            return date.toDateString() === selectedDate?.toDateString();
          })
          .sort((a, b) => b.createTime.getTime() - a.createTime.getTime())
      : [];
    setSelectedDateSnapshots(dateSnapshots);
    setSelectedSnapshot(dateSnapshots[0]?.id || '');
    if (dateSnapshots.length <= 0) {
      setSelectedOrigin('other');
    }
  }, [selectedDate, eonSnapshots]);

  return (
    <Stack
      maxHeight='60%'
      data-testid='restore-snapshot-drawer'
      className='w-full'
      direction='column'
      boxShadow='0px 10px 30px 0px rgba(0, 0, 0, 0.2)'
    >
      <Stack minHeight='0' className='w-full' direction='column'>
        <SnapshotDrawerHeader
          selectedDate={selectedDate}
          shouldShowTabs={
            !!(
              convertToEonSnapshotEnabled &&
              selectedDateSnapshots.length &&
              hasProviderSnapsInDate
            )
          }
          onClose={onClose}
          selectedSnapshotType={'eon'}
          onSnapshotOriginSelected={setSelectedOrigin}
          selectedOrigin={selectedOrigin}
          provider={entity.cloudProvider}
        />
        <Stack
          direction='column'
          alignItems='center'
          width='100%'
          overflow='scroll'
          minHeight='0'
        >
          {selectedDateSnapshots.map((snap) => {
            if (restorePanel) {
              return (
                <SnapshotRow
                  key={snap.id}
                  snap={snap}
                  vault={vaults.find((v) => v.id === snap.vaultId)}
                  isSelected={selectedSnapshot === snap.id}
                  onSelect={() => setSelectedSnapshot(snap.id)}
                  resource={entity}
                  snapshotSelection={selectedDateSnapshots.length > 1}
                  onRestore={() => {
                    setComponent({
                      panel: restorePanel,
                      props: {
                        resource: entity,
                        snapshot: snap,
                        originTab: 'Eon snapshots',
                      },
                    });
                  }}
                />
              );
            }

            if (
              entity.resourceType === ResourceType.AwsEc2 ||
              entity.resourceType === ResourceType.AzureVirtualMachine
            ) {
              return selectedOrigin === 'eon' ? (
                <VMSnapshotRow
                  resource={entity}
                  key={snap.id}
                  isSelected={selectedSnapshot === snap.id}
                  snap={snap}
                  vault={vaults.find((v) => v.id === snap.vaultId)}
                  onSelect={() =>
                    setSelectedSnapshot(
                      selectedSnapshot === snap.id ? '' : snap.id
                    )
                  }
                  snapshotSelection={selectedDateSnapshots.length > 1}
                  onRestore={(vols, actionType) => {
                    if (vols.length === 0) {
                      return;
                    }

                    if (entity.cloudProvider === Provider.Aws) {
                      return onAWSRestore(
                        vols.filter((x) => 'availabilityZone' in x),
                        actionType,
                        setComponent,
                        snap,
                        entity
                      );
                    }

                    if (entity.cloudProvider === Provider.Azure) {
                      return onAzureRestore(
                        vols.filter((x) => 'diskTier' in x),
                        actionType,
                        setComponent,
                        snap,
                        entity
                      );
                    }
                  }}
                />
              ) : (
                <OtherSnapshotsTab
                  data-testid='other-snapshots-tab'
                  resource={entity}
                  selectedDate={selectedDate}
                />
              );
            }
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

const onAWSRestore = (
  vols: SnapshotPropertiesVolumePropertiesInner[],
  actionType: RestoreActionType,
  setComponent: (component: PanelTypes) => void,
  snapshot: Snapshot,
  entity: InventoryResource
) => {
  switch (actionType) {
    case 'full-instance':
      setComponent({
        panel: Panels.RestoreEc2InstanceWizard,
        props: {
          snapshot,
          resourceId: entity.id,
          volumes: vols,
          sourceRegion: vols[0].region,
          resourceProperties: snapshot.resourceProperties,
        },
      });
      break;
    case 'convert-image':
      setComponent({
        panel: Panels.ConvertToAMIWizard,
        props: {
          snapshot,
          resourceId: entity.id,
          volumes: vols,
          sourceRegion: vols[0].region,
          resourceProperties: snapshot.resourceProperties,
        },
      });
      break;
    case 'volume':
    case 'convert-snapshot':
      setComponent({
        panel: Panels.RestoreVolumeWizard,
        props: {
          snapshot,
          instanceId: entity.id,
          providerVolumeId: vols[0].volumeName,
          volumeRegion: vols[0].region,
          actionType,
          originTab: 'Eon snapshots',
        },
      });
  }
};

const onAzureRestore = (
  vols: SnapshotPropertiesAzurePropertiesDiskPropertiesInner[],
  actionType: RestoreActionType,
  setComponent: (component: PanelTypes) => void,
  snap: Snapshot,
  entity: InventoryResource
) => {
  switch (actionType) {
    case 'full-instance':
      setComponent({
        panel: Panels.RestoreAzureVMWizard,
        props: {
          snapshot: snap,
          resource: entity,
        },
      });
      break;
    case 'volume':
      setComponent({
        panel: Panels.RestoreAzureDiskWizard,
        props: {
          snapshot: snap,
          resource: entity,
          disk: vols[0],
        },
      });
  }
};
