'use client';

import {
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  type IconProps,
} from '@mui/material';
import classNames from 'classnames';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useContext } from 'react';

import { Icon } from '@/components/shared/icon';
import { ThemeContext } from '@/components/theme';

export const MenuItem = ({
  href,
  title,
  iconClass,
  testId,
  disabled,
}: {
  title: string;
  href: string;
  iconClass: string;
  testId?: string;
  disabled?: boolean;
}) => {
  const pathname = usePathname();
  const selected = pathname.startsWith(href);

  return disabled ? (
    <Box
      className={`${classNames(
        'slide-left',
        {}
      )} w-[60px] h-[60px] cursor-default flex items-center justify-middle`}
    >
      <i
        className={`${iconClass} w-[24px] h-[24px] ml-[18px] text-[#a1a1aa]`}
      />
      <Typography
        className={classNames(
          'slide-left collapse absolute left-[60px] w-[200px] opacity-0 text-[#a1a1aa]'
        )}
      >
        {title}
      </Typography>
    </Box>
  ) : (
    <Link
      href={href}
      data-testid={testId}
      className={classNames('slide-left', {
        'bg-white': selected,
        'text-[var(--mui-palette-background-dark)]': selected,
        'hover:bg-[rgba(255,255,255,0.10)]': !selected,
      })}
    >
      <i className={`${iconClass} w-[24px] h-[24px] ml-[18px]`} />
      <Typography
        className={classNames(
          'slide-left collapse absolute left-[60px] w-[200px] opacity-0',
          {
            'text-[var(--mui-palette-background-dark)]': selected,
            'text-white': !selected,
          }
        )}
      >
        {title}
      </Typography>
    </Link>
  );
};

interface ActionIconProps extends IconProps {
  title: string;
  isActive: boolean;
  iconClass: string;
  onClick: () => void;
}

const ActionIcon = ({
  title,
  isActive,
  iconClass,
  onClick,
  ...rest
}: ActionIconProps) => {
  return (
    <Tooltip
      title={title}
      className='mr-[12px]'
      PopperProps={{ disablePortal: true }}
    >
      <Icon
        {...rest}
        sx={{
          backgroundColor: isActive
            ? 'white'
            : 'var(--mui-palette-background-dark)',

          '&:hover': {
            backgroundColor: isActive ? undefined : 'rgba(255, 255, 255, 0.10)',
          },

          '& i': {
            color: isActive ? 'var(--mui-palette-background-dark)' : 'white',
          },
        }}
        iconClass={iconClass}
        isActive={isActive}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export const NavigationActions = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';

  return (
    <Box className='navigation-actions slide-left collapse opacity-0 absolute left-[24px] right-[24px] bottom-[24px] '>
      <Stack
        direction='row'
        className='w-[257px]'
        alignItems='center'
        justifyContent='space-between'
      >
        <Stack direction='row'>
          <ActionIcon
            data-testid='light-mode'
            title='Light mode'
            isActive={!isDarkMode}
            iconClass='material-symbols-light-mode-outline-rounded'
            onClick={() => changeTheme('light')}
          />
          <ActionIcon
            data-testid='dark-mode'
            title='Dark mode'
            isActive={isDarkMode}
            iconClass='material-symbols-bedtime-outline-rounded'
            onClick={() => changeTheme('dark')}
          />
        </Stack>
        <Link
          prefetch={false}
          href='/docs'
          className='items-end flex-col justify-center'
          target='_blank'
        >
          <Button
            variant='outlined'
            sx={{
              borderColor: 'white',
              background: 'transparent',
              color: 'white',
            }}
          >
            <i className='material-symbols-help-outline-rounded w-[20px] h-[20px] mr-[8px]' />
            Eon Docs
          </Button>
        </Link>
      </Stack>
    </Box>
  );
};
