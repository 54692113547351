import { Box, Divider, Stack, Typography } from '@mui/material';
import type { DailySnapshotData, InventoryResource } from '@repo/api-gw-sdk';
import { useEffect, useMemo, useState } from 'react';

import RetentionSelector from '@/app/(dashboard)/settings/controls/retentionSelector';
import { useDAL } from '@/data/dal';
import type { BackupVault } from '@/data/vaults/backupVault';
import useBackupVaults from '@/data/vaults/useBackupVaults';
import { isDateValid } from '@/utils/dateTime';

import { PanelWrapper } from './panelWrapper';

import { Loader } from '../layout/loading';
import { CalculatedDateRange } from '../snapshots/convertToEon/calculatedDateRange';
import ConvertLifetimeLayout from '../snapshots/convertToEon/convertLifetimeLayout';
import {
  ConvertToEonDetailedColumn,
  ConvertToEonDetailedRow,
} from '../snapshots/convertToEon/convertToEonDetailedColumn';
import { ConvertToEonProviderDetails } from '../snapshots/convertToEon/convertToEonProviderDetails';
import OldestLatestSnapshots from '../snapshots/convertToEon/oldestLatestSnapshots';
import { EonSnapshotsMultipleDots } from '../snapshots/eonSnapshotsMultipleDots';
import { OtherSnapshotDot } from '../snapshots/otherSnapshotDot';
import { OtherSnapshotsMultipleDots } from '../snapshots/otherSnapshotsMultipleDots';
import SnapshotSelectionEmptyState from '../snapshots/snapshotSelection/snapshotSelectionEmptyState';
import { BackupVaultSelection } from '../vaults/backupVaultSelection';

interface ConvertAllToEonSnapshotsPanelProps {
  onClose: () => void;
  back: () => void;
  resource: InventoryResource;
}

export default function ConvertAllToEonSnapshotsPanel(
  props: ConvertAllToEonSnapshotsPanelProps
) {
  const { onClose, back, resource } = props;
  const [retentionInDays, setRetentionInDays] = useState(90);
  const { data: vaults, loading: loadingVaults } = useBackupVaults();
  const [selectedVault, setSelectedVault] = useState<BackupVault>(vaults?.[0]);

  useEffect(() => {
    if (vaults) {
      setSelectedVault(vaults[0]);
    }
  }, [vaults]);

  const dal = useDAL();

  const { body: snapshots, isLoading } = dal.convert.listDailySnapshots(
    resource.id
  );

  const filteredSnapshots = useMemo(() => {
    return (snapshots?.dailySnapshots || []).filter((x) =>
      isDateValid(x.startedAt)
    );
  }, [snapshots]);

  return (
    <PanelWrapper
      header={{
        onBack: back,
        title: 'Convert to Eon snapshots',
        subtitle: (
          <Stack width='87%'>
            <Typography variant='subtitle2' fontSize={14}>
              The AWS snapshots will be copied and converted to Eon’s snapshot
              format, allowing you to search and explore your data. If there are
              multiple snapshots in a day, only the last snapshot is converted.
              <br /> <br />
              The original snapshots won’t be deleted.
            </Typography>
          </Stack>
        ),
        onClose,
      }}
      footer={{
        primaryButton: {
          'data-testid': 'convert-to-eon-button',
          text: 'Convert',
          onClick: async () => {
            await dal.convert.resourceSnapshots(resource.id, {
              retentionDays: retentionInDays,
              vaultId: selectedVault.id,
            });
            onClose();
          },
          disabled: false,
        },
        secondaryButton: {
          'data-testid': 'convert-to-eon-cancel-button',
          text: 'Cancel',
          onClick: back,
        },
      }}
      testIdPrefix='convert-to-eon-snapshots-panel'
    >
      {isLoading ? (
        <Loader />
      ) : (
        <ConvertAllToEonBody
          retentionInDays={retentionInDays}
          setRetentionInDays={setRetentionInDays}
          otherSnapshots={filteredSnapshots}
          otherSnapshotsCount={resource.snapshotStorage.otherSnapshotCount || 0}
          vaults={vaults}
          loadingVaults={loadingVaults}
          selectedVault={selectedVault}
          setSelectedVault={setSelectedVault}
        />
      )}
    </PanelWrapper>
  );
}

interface ConvertAllToEonBodyProps {
  otherSnapshots: DailySnapshotData[];
  otherSnapshotsCount: number;
  retentionInDays: number;
  setRetentionInDays: (days: number) => void;
  loadingVaults: boolean;
  vaults: BackupVault[];
  selectedVault: BackupVault;
  setSelectedVault: (vault: BackupVault) => void;
}

const ConvertAllToEonBody = (props: ConvertAllToEonBodyProps) => {
  const {
    otherSnapshots,
    otherSnapshotsCount,
    retentionInDays,
    setRetentionInDays,
    selectedVault,
    setSelectedVault,
    loadingVaults,
    vaults,
  } = props;
  const [snapshotsByRetention, setSnapshotsByRetention] =
    useState(otherSnapshots);
  const sourceOldestDate = otherSnapshots[otherSnapshots.length - 1]?.startedAt;
  const latestDate = otherSnapshots[0]?.startedAt;
  const [oldestDate, setOldestDate] = useState<Date | undefined>(
    sourceOldestDate
  );
  const [oldestExpirationDate, setOldestExpirationDate] = useState<
    Date | undefined
  >();
  const [latestExpirationDate, setLatestExpirationDate] = useState<
    Date | undefined
  >();

  useEffect(() => {
    const today = new Date();
    const filteredRetentionSnaps = otherSnapshots.filter((snapshot) => {
      const date = new Date(snapshot.startedAt!);
      date.setDate(date.getDate() + retentionInDays);
      return date.getTime() > today.getTime();
    });
    setSnapshotsByRetention(filteredRetentionSnaps);
    setOldestDate(
      filteredRetentionSnaps[filteredRetentionSnaps.length - 1]?.startedAt
    );
  }, [retentionInDays, otherSnapshots]);

  useEffect(() => {
    if (oldestDate) {
      const date = new Date(oldestDate);
      date.setDate(date.getDate() + retentionInDays);
      setOldestExpirationDate(date);
    }
    if (latestDate) {
      const date = new Date(latestDate);
      date.setDate(date.getDate() + retentionInDays);
      setLatestExpirationDate(date);
    }
  }, [retentionInDays, oldestDate, latestDate]);

  const totalSnapshotsCount = otherSnapshotsCount;
  const snapshotsToConvertCount = useMemo(() => {
    const dateGroups = snapshotsByRetention.reduce((acc, obj) => {
      const date = obj.startedAt?.toUTCString();
      if (!date) {
        return acc;
      }
      if (!acc.has(date)) {
        acc.set(date, true);
      }

      return acc;
    }, new Map());

    return dateGroups.size;
  }, [snapshotsByRetention]);

  if (
    !sourceOldestDate ||
    !latestDate ||
    !oldestExpirationDate ||
    !latestExpirationDate
  ) {
    return (
      <Box marginTop='100px'>
        <SnapshotSelectionEmptyState description='No provider snapshots available' />
      </Box>
    );
  }

  const fields = [
    { label: 'Snapshot count', value: totalSnapshotsCount },
    {
      label: 'Date range',
      value: (
        <OldestLatestSnapshots
          oldestDate={sourceOldestDate}
          latestDate={latestDate}
          DotIcon={<OtherSnapshotDot />}
        />
      ),
    },
  ];

  return (
    <ConvertLifetimeLayout
      source={{
        dot: <OtherSnapshotsMultipleDots />,
        title: 'AWS snapshots',
        details: <ConvertToEonProviderDetails fields={fields} />,
      }}
      target={{
        dot: <EonSnapshotsMultipleDots fill={selectedVault?.backgroundColor} />,
        title: 'Eon snapshots',
        details: (
          <Stack gap='40px'>
            <ConvertToEonDetailedRow>
              <Stack gap='18px'>
                <Typography variant='subtitle1' fontSize={14}>
                  Retention (calculated from original snapshot dates)
                </Typography>
                <RetentionSelector
                  onChange={setRetentionInDays}
                  initialRetentionDays={retentionInDays}
                  initialFragment='days'
                  isInvalidRetention={!oldestDate || !latestDate}
                />
              </Stack>
            </ConvertToEonDetailedRow>
            <Stack gap='18px'>
              <ConvertToEonDetailedRow>
                <ConvertToEonDetailedColumn>
                  Snapshots to convert
                </ConvertToEonDetailedColumn>
                <ConvertToEonDetailedColumn>
                  {`${snapshotsToConvertCount} of ${totalSnapshotsCount}`}
                </ConvertToEonDetailedColumn>
              </ConvertToEonDetailedRow>
              {oldestDate && latestDate && (
                <CalculatedDateRange
                  oldestDate={oldestDate}
                  latestDate={latestDate}
                  oldestExpirationDate={oldestExpirationDate}
                  latestExpirationDate={latestExpirationDate}
                  selectedVault={selectedVault}
                />
              )}
            </Stack>
            <Divider />
            <Stack paddingBottom='24px' gap='24px'>
              <Typography variant='subtitle1' fontSize={14}>
                Store in vault:
              </Typography>
              <BackupVaultSelection
                onSelect={setSelectedVault}
                selectedVaults={selectedVault ? [selectedVault.id] : []}
                loading={loadingVaults}
                vaults={vaults}
              />
            </Stack>
          </Stack>
        ),
      }}
    />
  );
};
