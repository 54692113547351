import { Stack, Typography } from '@mui/material';

import OptionMenu from '@/@core/components/option-menu';
import { useDAL } from '@/data/dal';
import { isDateValid } from '@/utils/dateTime';
import dayjs, { DATE_FORMAT } from '@/utils/dayjs';

interface SecurityScanMutedBarProps {
  resourceId: string;
  onEntityChange?: () => Promise<void>;
}

export const SecurityScanMutedBar = (props: SecurityScanMutedBarProps) => {
  const { resourceId, onEntityChange } = props;
  const dal = useDAL();
  const { body: scans } = dal.security.scansSummary.get(resourceId);

  const triggerUnmuteAlerts = () => {
    alert(`Unmute alerts for ${resourceId}`);
    void onEntityChange?.();
  };

  const firstInfectedDate = isDateValid(scans?.firstInfected?.createdTime)
    ? ` detected (${dayjs.utc(scans.firstInfected.createdTime).format(DATE_FORMAT)})`
    : '';

  return (
    <Stack
      direction='row'
      gap='12px'
      alignItems='center'
      justifyContent='center'
    >
      <Typography>{`Ransomware alert muted${firstInfectedDate}`}</Typography>
      <OptionMenu
        icon='material-symbols-more-horiz'
        iconClassName='text-textPrimary'
        options={['Unmute alerts']}
        onOptionSelected={triggerUnmuteAlerts}
      />
    </Stack>
  );
};
